<template>
  <div class="loading">
    Loading...
  </div>
</template>

<script>
export default {};
</script>

<style></style>
