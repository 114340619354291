<template>
  <nav id="nav">
    <div class="bar container-fluid">
      <button class="btn btn-function" @click.prevent="showOptions()">
        Muokkaa hakua
        <span class="icon-chevron-down" aria-hidden="true"></span>
      </button>
      <div class="filters"></div>
      <button
        class="btn btn-text"
        @click="$router.push({ name: 'Favorites' })"
        v-if="false != false"
      >
        Suosikit <span class="label">{{ countFavorites }}</span>
      </button>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    openSearch: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    ...mapGetters({
      countFavorites: "favorites/countFavorites"
    })
  },
  methods: {
    showOptions() {
      this.$emit("openSearch", true);
    }
  },
  mounted() {
    if (this.openSearch) this.showOptions();
  }
};
</script>

<style lang="scss"></style>
