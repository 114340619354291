<template>
  <div
    v-touch:swipe.left="incrementImage"
    v-touch:swipe.right="decrementImage"
    @mouseover="loopImages()"
    @mouseout="revertImage()"
  >
    <template v-if="isApproved">
      <img
        class="toyota-approved"
        src="@/images/toyota-approved.svg"
        alt="Toyota Approved vaihtoauto"
      />
    </template>
    <img :src="computedImage" alt="" @load="resume" />
  </div>
</template>

<script>
export default {
  props: {
    images: {
      type: Array,
      required: true
    },
    isApproved: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    image: null,
    index: 0,
    interval: null,
    paused: false
  }),
  computed: {
    computedImage() {
      let image = "";
      if (this.image) {
        if (this.image.indexOf("http:") < 0) {
          image = process.env.VUE_APP_BASEURL + "images/medium/" + this.image;
        } else {
          image = this.image.replace("http:", "https:");
        }
      }
      return image;
    }
  },
  methods: {
    resume() {
      this.paused = false;
    },
    loopImages() {
      this.interval = setInterval(() => {
        if (!this.paused) {
          this.paused = true;
          this.incrementImage();
        }
      }, 800);
    },
    incrementImage() {
      this.index = this.index < this.images.length - 1 ? this.index + 1 : 0;

      this.image = this.images[this.index];
    },
    decrementImage() {
      this.index = this.index === 0 ? this.images.length - 1 : this.index - 1;

      this.image = this.images[this.index];
    },
    revertImage() {
      clearInterval(this.interval);
      this.image = this.images[0];
      this.index = 0;
    }
  },
  mounted() {
    this.image = this.images ? this.images[0] : null;
  }
};
</script>

<style lang="scss">
// .slide-enter-active,
// .slide-leave-active {
//   transition: left 0.8s;
//   left: 0;
//   position: relative;
// }
// .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
//   position: absolute;
//   left: -100%;
// }
// .slide-enter {
//   // position: absolute;
//   left: 100%;
// }
</style>
