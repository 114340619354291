<template>
  <a @click="showVehicle()">
    <div class="vehicle-card" ref="card">
      <card-image
        :images="vehicle.images.marketingImageUrls"
        :isApproved="isApproved(vehicle)"
        v-if="'images' in vehicle"
        class="card-image"
        ref="image"
      ></card-image>
      <div class="card-content">
        <div class="row pb-2">
          <div class="col">
            <h2 class="card-title mb-0">
              <strong> {{ vehicle.make }} {{ vehicle.model }} </strong>
            </h2>
            <strong>{{ vehicle.price | price }} </strong>
            <template v-if="vehicle.calculation">
              <span
                v-if="
                  parseFloat(
                    vehicle.calculation.calculation_data.monthly_installment
                  ) > 0
                "
              >
                tai
                {{
                  vehicle.calculation.calculation_data.monthly_installment
                    | price
                }}/kk
              </span>
            </template>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <p class="m-0">{{ vehicle.type }}</p>
          </div>
          <div class="col text-right" v-if="false != false">
            <a
              v-if="!isFavorite(vehicle.licensePlateNumber)"
              @click.stop="setFavorite()"
            >
              <span class="icon-bookmark"></span> Lisää suosikiksi
            </a>
            <a v-else @click.stop="removeFavorite()">
              <strong>Lisätty suosikiksi</strong>
            </a>
          </div>
        </div>
        <div
          class="tags"
          v-if="
            'additional_data' in vehicle &&
              vehicle.additional_data &&
              'allowed_products' in vehicle.additional_data
          "
        >
          <label
            class="tag"
            v-if="vehicle.additional_data.allowed_products.flex_calculation"
          >
            Toyota Easy Osamaksu -rahoitus
          </label>
          <label
            class="tag"
            v-if="vehicle.additional_data.allowed_products.maintenance"
          >
            Toyota huoltosopimus
          </label>
        </div>
      </div>
      <div class="card-bottom" ref="cardbottom" @click.stop="techModal()">
        <hr />
        <div class="row">
          <div class="col essentials">
            <span>{{ vehicle.modelYear }}</span
            ><span>{{ vehicle.mileage | mileage }}</span>
            <span>{{ vehicle.fuelType | fuelType }}</span>
            <span>{{ vehicle.nedcEmissions }}g CO<sub>2</sub></span>
            <span>{{ vehicle.technicalInformation.maxPower }} kW</span>
          </div>
          <div class="col-auto">
            <span class="icon-chevron-down"></span>
          </div>
        </div>
      </div>
      <div
        class="technical"
        v-show="showTechnical"
        :style="{ top: techBottom + 'px' }"
        @mouseleave="showTechnical = false"
        @click.stop="showTechnical = false"
      >
        <div class="d-flex justify-content-between line">
          <strong>Vuosimalli</strong> <span>{{ vehicle.modelYear }}</span>
        </div>
        <div class="d-flex justify-content-between line">
          <strong>Mittarilukema</strong>
          <span>{{ vehicle.mileage | mileage }}</span>
        </div>
        <div class="d-flex justify-content-between line">
          <strong>Polttoaine</strong>
          <span>{{ vehicle.fuelType | fuelType }}</span>
        </div>
        <div class="d-flex justify-content-between line">
          <strong>Korimalli</strong>
          <span>{{ vehicle.bodyType | bodyType }}</span>
        </div>
        <div class="d-flex justify-content-between line">
          <strong>CO<sub>2</sub>-päästöt</strong>
          <span>{{ vehicle.nedcEmissions }} g</span>
        </div>
        <div class="d-flex justify-content-between line">
          <strong>Teho</strong>
          <span>{{ vehicle.technicalInformation.maxPower }} kW</span>
        </div>
        <div class="d-flex justify-content-between line">
          <strong>Väri</strong>
          <span>{{ vehicle.colorText.fi }}</span>
        </div>
        <div class="d-flex justify-content-between line">
          <strong>Moottoritilavuus</strong>
          <span
            >{{ vehicle.technicalInformation.engineVol }} cm<sup>3</sup></span
          >
        </div>
      </div>
    </div>
  </a>
</template>

<script>
import CardImage from "./Image";
import { mapGetters } from "vuex";
import slugify from "slugify";
import helpers from "@/plugins/helpers";
export default {
  props: {
    vehicle: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showTechnical: false,
    techBottom: 0
  }),
  computed: {
    ...mapGetters({
      isFavorite: "favorites/isFavorite"
    })
  },
  methods: {
    showVehicle() {
      this.$store.commit("vehicle/SET_VEHICLE", this.vehicle);
      this.$router.push({
        name: "SingleVehicle",
        params: {
          id: slugify(this.vehicle.formattedLicensePlateNumber, {
            lower: true
          }),
          make: slugify(this.vehicle.make, { lower: true }),
          model: slugify(this.vehicle.model, { lower: true })
        }
      });
    },
    setFavorite() {
      this.$store.commit("favorites/ADD_FAVORITE", this.vehicle);
    },
    removeFavorite() {
      this.$store.commit(
        "favorites/REMOVE_FAVORITE",
        this.vehicle.licensePlateNumber
      );
    },
    techModal() {
      this.techBottom =
        this.$refs.card.clientHeight - this.$refs.cardbottom.clientHeight;
      this.showTechnical = true;
    },
    loopImages() {
      this.$refs.image.loopImages();
    },
    revertImage() {
      this.$refs.image.revertImage();
    },
    isApproved(vehicle) {
      return helpers.isApproved(vehicle);
    }
  },
  mounted() {
    console.log(this.vehicle);
  },
  components: {
    CardImage
  }
};
</script>

<style lang="scss">
@import "../../../styles/colors";

.t-vaihtoautohaku {
  .vehicle-card {
    display: flex;
    flex-direction: column;
    text-align: left;
    border-radius: 4px;
    background: #fff;
    //box-shadow: 0 0 25px 5px rgba(0, 0, 0, 0.15);
    border: 1px solid #cecfd0;
    position: relative;
    height: 100%;
    cursor: pointer;
    transition: all 0.3 ease-in-out;

    .card-image {
      overflow: hidden;
      display: block;
      position: relative;
      width: 100%;
      background: #adadb0;
      padding-top: calc(900 / 1600 * 100%);
      padding-top: calc(1080 / 1620 * 100%);
      //padding-top: calc(1080 / 1440 * 100%);
      img {
        overflow: hidden;
        object-fit: cover;
        //max-width: 100%;
        min-width: 100%;
        min-height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      &:before {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100px;
        z-index: 1;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.24) 100%
        );
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.15) 70%,
          rgba(0, 0, 0, 0.24) 100%
        );
      }
      /*.inner {
        display: block;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
        transition: all 0.3s ease-in-out;
      }*/
      .toyota-approved {
        position: absolute;
        bottom: 15px;
        left: 15px;
        top: auto;
        z-index: 2;
        max-width: 200px;
        min-height: 0;
        min-width: 0;
        transform: initial;
      }
      /*&:before {
        content: "";
        padding-top: calc(900 / 1600 * 100%);
        display: block;
      }*/
    }
    &:hover {
      .card-image .inner {
        transform: scale(1.035);
      }
    }
    .card-content {
      padding: 10px 15px 10px;
      .card-title {
        font-size: 20px;
      }
      p {
        font-size: 13px;
        line-height: 20px;
      }
      .tag {
        font-size: 13px;
        line-height: 20px;
        color: $primary_grey;
        &::before {
          content: "\f00c";
          color: $primary_red;
          font-family: FontAwesome;
        }
      }
    }
    .card-bottom {
      margin-top: auto;
      padding: 0 15px 0.5rem;
      font-size: 13px;
      color: #494950;
      .row {
        padding: 0.75rem 0;
      }
      .icon-chevron-down {
        color: #ff0022;
      }
      .hybrid {
        color: #00a0f0;
        font-family: ToyotaType-Semibold, Arial, Helvetica, sans-serif;
        font-style: italic;
        text-transform: uppercase;
      }
      .essentials {
        overflow: hidden;
        height: 3rem;
        span {
          padding: 0.25rem 1rem;
          border-left: 1px solid #cecfd0;
          display: inline-block;
          &:first-child {
            padding-left: 0;
            border-left: 0;
          }
        }
      }
      hr {
        width: 100%;
        border: none;
        border-top: 1px solid $tme_grey_5;
        margin: 0.5rem 0;
      }
    }
    .technical {
      padding: 15px;
      width: calc(100% + 2px);
      background-color: #fff;
      position: absolute;
      box-sizing: border-box;
      z-index: 3;
      font-size: 13px;
      line-height: 20px;
      left: -1px;
      border-right: 1px solid #cecfd0;
      border-left: 1px solid #cecfd0;
      border-bottom: 1px solid #cecfd0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      .row {
        position: relative;
      }
      .line {
        margin-bottom: 0.25rem;
        & > * {
          width: 50%;
        }
      }
    }
  }
}
</style>
