<template>
  <div class="pagination" v-if="total > 0">
    <div class="container-fluid">
      <div class="button-group">
        <template v-if="currentPage > 3">
          <button @click="toPage(1)">&laquo;</button>
        </template>
        <button
          v-for="page in pages"
          :key="page.name"
          type="button"
          :disabled="page.isDisabled"
          @click="toPage(page.name)"
        >
          {{ page.name }}
        </button>
        <template v-if="lastPage - currentPage > 2">
          <button @click="toPage(lastPage)">&raquo;</button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data: () => ({
    maxPages: 5
  }),
  computed: {
    ...mapState({
      currentPage: "currentPage",
      perPage: "perPage",
      lastPage: "lastPage",
      total: "total"
    }),
    startPage() {
      if (this.currentPage === 1) {
        return 1;
      } else if (this.currentPage === 2) {
        return 1;
      } else if (this.currentPage === this.lastPage) {
        return this.lastPage - this.maxPages + 1;
      }
      console.log(Math.floor(this.maxPages / 2));
      return this.currentPage - Math.floor(this.maxPages / 2);
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxPages - 1, this.lastPage);
        i += 1
      ) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        });
      }

      return range;
    }
  },
  methods: {
    ...mapActions({
      toPage: "toPage"
    }),
    changePage(no) {
      this.toPage(no).then(() => {
        window.scroll(0, 0);
      });
    }
  }
};
</script>

<style lang="scss">
.pagination {
  padding-top: 15px;
  padding-bottom: 30px;
  border-radius: 0 !important;
  box-shadow: 0px -3px 12px rgba(0, 0, 0, 0.05);
  position: sticky;
  bottom: 0;
  background: #fff;
  z-index: 5;
  left: 0;
  @media (min-width: 992px) {
    text-align: center;
    padding: 15px;
  }
  .button-group {
    display: inline-block;
    border-radius: 2rem;
    border: 1px solid #cecfd0;
    padding: 0;
    overflow: hidden;
  }
  button {
    padding: 0.75rem 1.5rem;
    background-color: transparent;
    border: 0;
    border-right: 1px solid #cecfd0;
    transition: all 0.3s ease-in-out;
    &:last-child {
      border-right: 0;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
