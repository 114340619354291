<template>
  <div class="results">
    <navbar :openSearch="!results.length" @openSearch="toggleSearch()"></navbar>
    <search-options v-if="showOptions"></search-options>
    <loading v-if="resultsLoading"></loading>
    <main class="content container-fluid px-lg-3" v-else>
      <div v-if="!results.length">
        <h3 class="py-3 text-center">
          Emme löytäneet osumia valitsemillasi hakukriteereillä
        </h3>
        <p class="text-center">Muokkaa hakua ja yritä uudestaan.</p>
      </div>
      <div class="row py-3">
        <div class="col">{{ total }} {{ total > 1 ? "autoa" : "autoa" }}</div>
        <div class="col">
          <div class="sorttaus">
            <div class="icon">
              <svg width="30" height="24" fill="none">
                <path
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  d="M16 6h12m-12 6h8"
                ></path>
                <path
                  d="M7 15l1 1 2 2 3-3m-3 3V6"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
                <path
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  d="M16 18h2"
                ></path>
              </svg>
            </div>
            <select id="filter" v-model="filter">
              <label for="filter">Hakutulosten järjestys</label>
              <option value="price_asc">Edullisin ensin</option>
              <option value="price_desc">Kallein ensin</option>
              <option value="year_asc">Vanhin ensin</option>
              <option value="year_desc">Uusin ensin</option>
            </select>
          </div>
        </div>
      </div>
      <div class="cards ">
        <vehicle-card
          v-for="vehicle in results"
          :key="vehicle.id"
          :vehicle="vehicle"
        ></vehicle-card>
      </div>
    </main>
    <pagination id="pagination" title="hakutulossivut"></pagination>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import VehicleCard from "@/components/Vehicle/Card/Card";
import Pagination from "@/components/Pagination";
import Loading from "@/components/Loading";
import { mapActions, mapState } from "vuex";
import SearchOptions from "@/components/Search/SearchOptions";
export default {
  data: () => ({
    showOptions: false
  }),
  computed: {
    ...mapState({
      results: "results",
      resultsLoading: "resultsLoading",
      total: "total",
      filters: "filters"
    }),
    filter: {
      get() {
        return this.filters.sortBy + "_" + this.filters.sortDir.toLowerCase();
      },
      set(val) {
        console.log(val);
        val = val.split("_");
        this.$store.commit("filters/SET_FIELD", {
          field: "sortBy",
          value: val[0]
        });
        this.$store.commit("filters/SET_FIELD", {
          field: "sortDir",
          value: val[1]
        });
        this.fetch();
      }
    }
  },
  methods: {
    ...mapActions({
      fetch: "fetchResults"
    }),
    toggleSearch() {
      this.showOptions = !this.showOptions;
    }
  },
  components: {
    SearchOptions,
    VehicleCard,
    Pagination,
    Navbar,
    Loading
  }
};
</script>

<style lang="scss">
.t-vaihtoautohaku .results {
  //background: linear-gradient(180deg, #ededed 0%, #ffffff 100%);
  .cards {
    //width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 1.5em;
    @media (min-width: 992px) {
      grid-template-columns: 50% 50%;
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: 1140px) {
      grid-template-columns: 33% 33% 33%;
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  .sorttaus {
    position: relative;
    padding-left: 38px;
    display: block;
    float: right;
    select {
      background: transparent;
      width: auto;
      cursor: pointer;
      border: 0;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;
      height: auto;
      border-radius: 0;
      width: auto;
      box-shadow: none;
      &:focus {
        box-shadow: none;
      }
    }
    .icon {
      position: absolute;
      left: 0;
      top: 0;
      svg {
      }
    }
  }
}
</style>
